var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"80%","margin":"30px 10% 80px"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"back"},[_c('div',{staticClass:"back-button",on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v(" 返回列表 ")])]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hdyy-details-top"},[_c('div',{staticClass:"hdyy-details-top-left"},[_c('img',{attrs:{"src":require("../../../static/hdyy_1.png"),"alt":""}})]),_c('div',{staticClass:"hdyy-details-top-right"},[_c('div',{staticClass:"title"},[_vm._v(" 【活动即将开始】— 郑成功收费台湾之战 ")]),_c('div',{staticClass:"hdyy-details-top-right-middle"},[_c('div',[_vm._v(" 时间：2016年08/08 上午10：00 ")]),_c('div',[_vm._v(" 主办单位：海军博物馆 ")]),_c('div',[_vm._v(" 地点：青岛市海军博物馆ABCD区 ")])]),_c('div',{staticClass:"yy-button"},[_vm._v(" 立即预约 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-title"},[_c('i',{staticClass:"iconfont iconanchor-full"}),_c('div',{staticStyle:{"margin":"0 20px"}},[_vm._v(" 【活动预告】— 郑成功收费台湾之战 ")]),_c('i',{staticClass:"iconfont iconanchor-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-xinxi"},[_c('div',[_vm._v(" 时间：2016年10/08 上午10：00 ")]),_c('div',{staticStyle:{"margin-left":"45px"}},[_vm._v(" 主办单位：中国人民解放军海军博物馆 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"left","font-size":"13px","color":"#333333"}},[_c('p',[_vm._v(" 661年3月21日，郑成功率军25000人、战船数百艘，从金门渡海，进攻台湾。30日午后，郑成功巧妙地渡过了荷兰人未设防的水道，包围了荷兰人防守的赤嵌城，击沉荷兰人的主力舰，在台湾登陆。荷军多次反扑均遭失败，于次年2月1日宣布投降... ")]),_c('p',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 郑成功水军所创立的赫赫战功，将永远镌刻在中华民族的光辉史册上661年3月21日，郑成功率军25000人、战船数百艘，从金门渡海，进攻台湾。30日午后，郑成功巧妙地渡过了荷兰人未设防的水道，包围了荷兰人防守的赤嵌城，击沉荷兰人的主力舰，在台湾登陆。荷军多次反扑均遭失败，于次年2月1日宣布投降... ")]),_c('p',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 郑成功水军所创立的赫赫战功，将永远镌刻在中华民族的光辉史册上城，击沉荷兰人的主力舰，在台湾登陆。荷军多次反扑均遭失败，于次年2月1日宣布投降... ")]),_c('p',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 郑成功水军所创立的赫赫战功，将永远镌刻在中华民族的光辉史册上 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-title"},[_c('i',{staticClass:"iconfont iconanchor-full"}),_c('div',{staticStyle:{"margin":"0 20px","font-size":"20px"}},[_vm._v(" 活动场景 ")]),_c('i',{staticClass:"iconfont iconanchor-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hdcj-list"},[_c('img',{attrs:{"src":require("../../../static/hdyy_details_1.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../../static/hdyy_details_2.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../../static/hdyy_details_3.png"),"alt":""}})])
}]

export { render, staticRenderFns }